<template>
  <div class="about">
    <div class="ad" v-if="infoBanner">
      <img :src="infoBanner.webImg" />
    </div>
    <h2></h2>
    <div :class="'lml-box ' + ('box-' + (index+1) )" v-for="item,index in infoMobile" :key="index">
        <img :src="item.webImg" />
        <div class="content">
            <h3>{{item.title}}</h3>
            <p>
              <span class="desc" v-for="(_item,_itemindex) in item.subTitle.split('\n')" :key="_itemindex">
                  {{_item}}
                </span>
            </p>
            
        </div>
    </div>
    
  </div>
</template>

<script>
import Utils from '@/api/Utils.js' 
export default {
  name: 'About',
  components: {
    
  },
  data(){
    return {
      infoBanner: null,
      infoMobile: null
    }
  },
  mounted(){
    
    
    Utils.post(`v1/outer/article/brand`).then((res)=>{
      if(res && res.code == 200){
        this.infoBanner = res.data.infoBanner
        this.infoMobile = res.data.infoMobile
        
      }
    })
  }
}
</script>
<style scoped lang="stylus">
.about{
  background : #f7f8f8;
  padding-bottom: 120px;
}
.ad{
   height: 538px;
   overflow: hidden;
   position relative
   img{
      display block 
      height: 538px;
      margin: 0 auto;
      position absolute
      left: 50%
      transform translate3d(-50%,0,0)
      top: 0
   }
}
.mb{
  margin-bottom: 210px;
}
h2{
  margin: 87px 0 57px;
  background: url('./title.png') 50% 0 no-repeat;
  background-size: auto 31px
  height 31px;
}
.lml-box{
    width 853px
    position relative
    margin: 0 auto 47px
    img{
        display block
        width 853px
    }
    .content{
        position absolute
        z-index: 2
        top: 99px
        line-height 22px
        font-size: 12px
    }
    h3{
        margin-bottom 28px
        font-weight: bold
    }
    span{
      display block
      line-height 22px
      min-height 10px
    }
    &.box-1{
        .content{
            left: 535px
            width 311px
            top: 230px
        }
        
    }
    &.box-2{
        .content{
            left: 0
            width 250px
            top: 205px
        }
        
    }
    &.box-3{
        .content{
            left: 425px
            width 427px
            top: 316px
        }
        
    }
    &.box-4{
        margin-bottom 0
        .content{
            left: 0
            width 365px
            top: 217px
        }
        
    }
}
</style>
